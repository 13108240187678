@media only screen and (min-width: 576px){

.primary-nav{
    background: #fa2509;
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 35px;
    overflow: hidden;
    padding: 0 1%;
    top: 0;
}

.primary-nav a{
    color: #ffffff;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
}

.primary-nav a:hover{
    color: #ffcccc;
}

.primary-nav .font-change-flex{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.primary-nav .font-change-button{
    background-color: white;
    border: 0;
    color: black;
    padding: 5px;
    height: fit-content;
}

.primary-nav .sm-font-change{
    font-size: 0.8rem;
    line-height: 0.8rem;
}

.primary-nav .m-font-change{
    font-size: 0.9rem;
    line-height: 0.9rem;
}

.primary-nav .l-font-change{
    font-size: 1rem;
    line-height: 1rem;
}

}

@media only screen and (max-width: 575px){
    .primary-nav{
    display: none;
    };
}