@media only screen and (min-width: 576px){

.footer-container-background{
    background-color:#171717;
    margin-top: 15vh;
    /* position: relative; */
}

.footer-flex-box{
    padding-top: 5vh;
    padding-bottom: 5vh;
    width: 70.5vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
}

.footer-section{
    height: auto;
    overflow: hidden;
    /* position: relative; */
}

.footer-section-header{
    font-size: 1.1rem;
    font-weight: 900;
    padding-top: 5vh;
    padding-bottom: 3vh;
}

.footer-section button{
    max-width: 14vw;
    padding-top: 1vh;
    padding-bottom: 1.5vh;
    padding-right: 2vw;
    background-color: inherit;
    border: 0;
    text-align: left;
    font-size: 0.9rem;
    line-height: 1.6rem;
}

.footer-section button:hover{
    color: #fa2509;
}

.footer-end{
    width: 70.5vw;
    margin-left: auto;
    margin-right: auto;
}

.footer-end hr{
    color: #fa2509;
}

.footer-social-media-link{
    display: flex;
    flex-direction: column;
    gap: 1vh;
    align-content: center;
    padding: 1vh 0 3vh 0;
}

.logo-address-container{
    display: flex;
    justify-content: space-between;
}

.physical-adress{
    font-size: 0.9rem;
    line-height: 1.6rem;
}

.copyright-text{
    font-size: 0.8rem;
    opacity: 0.8;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
}

.copyright-text img{
    width: 1.1rem;
    height: 1.1rem;
}

.resolute-logo-footer{
    font-size: 1.6rem;
    line-height: 1.6rem;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    align-items: center;
}

.resolute-logo-footer text{
    color: #fa2509;
}

.resolute-logo-footer section{
    font-size: 0.9rem;
    line-height: 1.2rem;
    font-weight: normal;
}

.footer-social-media-link button{
    background-color: inherit;
    border: 0;
    filter: brightness(0) invert(1);
}

.social-links-logo{
    display: flex;
    gap: 1rem;
}

.social-btn img{
    width: 2.4rem;
    height: 2.4rem;
}

}

.back-to-top a{
    background-color: #fa2509;
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 50px;
    top: 88%;
    padding: 10px 11px 10px 11px;
    border-radius: 50%;
    cursor: pointer;
}

@media only screen and (max-width: 575px){   

    .back-to-top a{
        right: 20px;
        top: 90%;
    }

.footer-container-background{
    background-color:#171717;
    margin-top: 15vh;
}

.footer-flex-box{
    padding-top: 5vh;
    padding-bottom: 5vh;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.footer-section{
    height: auto;
    overflow: hidden;
}

.footer-section-header{
    font-size: 1rem;
    font-weight: 900;
    padding-top: 5vh;
    padding-bottom: 3vh;
}

.footer-section button{
    max-width: 90vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    background-color: inherit;
    border: 0;
    text-align: left;
    font-size: 0.9rem;
    line-height: 1.2rem;
}

.footer-section button:hover{
    color: #fa2509;
}

.footer-end{
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.footer-end hr{
    color: #fa2509;
    margin-bottom: 5vh;
}

.footer-social-media-link{
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.logo-address-container{
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.physical-adress{
    font-size: 0.9rem;
    line-height: 1.6rem;
}

.physical-adress img{
    width: 2.4rem;
    height: 2.4rem;
}

.copyright-text{
    width: 100%;
    text-align: center;
    margin-bottom: 5vh;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.copyright-text img{
    width: 1.1rem;
    height: 1.1rem;
}

.resolute-logo-footer{
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bolder;
    margin-bottom: 1vh;
}

.resolute-logo-footer text{
    color: #fa2509;
}

.resolute-logo-footer section{
    font-size: 0.8rem;
    font-weight: normal;
    margin-top: 1vw;
}

.footer-social-media-link button{
    margin-top: 3vh;
    background-color: inherit;
    border: 0;
    filter: brightness(0) invert(1);
    margin-bottom: 3vh;
}

.social-links-logo{
    display: flex;
    gap: 1rem;
}

.social-btn img{
    width: 2.4rem;
    height: 2.4rem;
}

}

