@media only screen and (min-width: 576px){

.resolute-with-numbers{
    margin-top: 10vh;
    width: 70.5vw;
    height: 36vw;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #fa2509;
    padding: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.resolute-with-numbers .container{
    margin: 0;
    padding-top: 10vh;
    width: 100%;
    height: 100%;
    border: 0;
}

.resolute-with-numbers .heading{
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
}

.resolute-with-numbers .heading text{
    color: #fa2509;
}

.resolute-with-numbers .data{
    font-size: 2.6rem;
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 5vh;
    padding-left: 6vw;
    padding-right: 6vw;
}

.resolute-with-numbers .number{
    width: auto;
    height: auto;
    text-align: center;
    }

.resolute-with-numbers .data p{
    font-size: 0.9rem;
    margin-top: 1rem;
    width: 16vw;
}
}

@media only screen and (max-width: 575px){

.resolute-with-numbers{
    margin-top: 10vh;
    width: 70.5vw;
    height: 45vw;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #fa2509;
    padding: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: none;
}

.resolute-with-numbers .container{
    margin: 0;
    padding-top: 10vh;
    width: 100%;
    height: 100%;
    border: 0;
}

.resolute-with-numbers .heading{
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.resolute-with-numbers .heading text{
    color: #fa2509;
}

.resolute-with-numbers .data{
    font-size: 3rem;
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10vh;
    padding-left: 6vw;
    padding-right: 6vw;
}

.resolute-with-numbers .number{
    width: auto;
    height: auto;
    text-align: center;
    }

.resolute-with-numbers .data p{
    font-size: 1rem;
}
}
    