@media only screen and (min-width: 576px){



.back-button{
    margin-bottom: 10vh;
}

.solutions-container{
    width: 70.5vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.2vw;
    display: flex;
    justify-content: space-between;
}

.solutions-container-wrapper{
    width: 70.5vw;
    /* height:100vh; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.2vw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* overflow-x: scroll */
}
.solution-card-container-wrapper{
    width: 100vw;
    border: 2px solid #fa2509;
    overflow: hidden;
    margin-bottom: '35px';
}

.solution-card-image-wrapper{
    width: 100%;
    height: 20vw;
    border-bottom: 2px solid #fa2509;
}

.solution-card-container{
    width: 22.16vw;
    border: 2px solid #fa2509;
    background: linear-gradient(to right, #121212 50%, #fa2509 50%);
    overflow: hidden;
    background-size: 200% 100%;
    background-position:left bottom;
    transition:all 1s ease;
}
.solution-card-container:hover {
    background-position:right bottom;
}

.filter{
    width: 22.16vw;
    margin-left: 14.5vw;
}

.solution-card-image{
    width: 100%;
    height: 20vw;
    border-bottom: 2px solid #fa2509;
}

.solution-card-heading{
    font-size: 1rem;
    line-height: 1.8rem;
    height: 5rem;
    padding: 2vh;
    font-weight: 900;
}

.solution-card-description{
    font-size: 0.9rem;
    line-height: 1.4rem;
    padding: 0vh 2vh 2vh 2vh;
    height: 11.2rem;
}

}

@media only screen and (max-width: 575px){

.back-button{
    margin-bottom: 5vh;
}
.filter{
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}
.solution-card-container{
    width: 90vw;
    margin-top: 5vw;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #fa2509;
    overflow: hidden;
}

.solution-card-image{
    width: 100%;
    height: 65vw;
    border-bottom: 1px solid #fa2509;
}

.solution-card-heading{
    font-size: 1rem;
    line-height: 1.8rem;
    height: 5rem;
    padding: 2vh;
    font-weight: 900;
}

.solution-card-description{
    font-size: 0.8rem;
    line-height: 1.4rem;
    padding: 0vh 2vh 2vh 2vh;
    opacity: 0.9;
}
}
    