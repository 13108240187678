@import url("https://fonts.googleapis.com/css?family=Poppins");

*{
  font-family: 'Poppins', 'sans-serif';
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  color: #E1E1E1;
}

body{
  background-color: #121212;
}
a{
  color: white;
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
 }

::-webkit-scrollbar-thumb {
  background-color: #fa2509;
}



@media only screen and (min-width: 576px){

.top-spacing{
  width: 10vw;
  height: 98px;
}  

.resolute-button-container{
  width: 70.5vw;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.bullet-header{
  padding-top: 14.5vh;
  padding-left: 14.5vw;
  display: flex;
  align-items: center;
}

.bullet{
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #fa2509;
  margin-right: 1rem;
}

.bullet-header{
  font-size: 1rem;
  font-weight: 400;
}

.resolute-button{
  color: #fa2509;
  background-color: transparent;
  font-weight: normal;
  display: flex;
  margin-top: 10vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  gap: 0.6rem;
  border: 2px solid #fa2509;
  border-radius: 40px;
  --c: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.resolute-button:hover{
  gap: 1.8rem;
  background-color: #fa2509;
  color: #ffffff;
  --c: brightness(0) invert(1);
}

.resolute-button img{
  height: 1.6rem;
  width: 1.6rem;
  filter: var(--c);
}

.back-button .resolute-button img{
  transform: scaleX(-1);
}

.nestedpage-header{
  width: 70.5vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  margin-bottom: 8vh;
  font-size: 1.6rem;
  font-weight: 900;
  color: #fa2509;
}
}

@media only screen and (min-device-width: 576px) and (max-device-width: 971px){

  .top-spacing{
    width: 10vw;
    height: 65px;
  } 
}

@media only screen and (max-width: 575px){

.top-spacing{
  width: 10vw;
  height: 65px;
} 
.bullet-header{
  padding-top: 8vh;
  padding-left: 5vw;
  padding-right: 5vw;
}

.bullet{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #fa2509;
    margin-right: 1rem;
}

.bullet-header{
    font-size: 1rem;
    font-weight: 400;
}

.resolute-button-container{
    width: 90vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.resolute-button{
  color: #fa2509;
  background-color: transparent;
  font-weight: normal;
  display: flex;
  margin-top: 5vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
  padding: 1rem 1.5rem;
  gap: 0.3rem;
  border: 1px solid #fa2509;
  border-radius: 40px;
  --c: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.resolute-button:hover{
  gap: 0.9rem;
  background-color: #fa2509;
  color: #ffffff;
  --c: brightness(0) invert(1);
}

.resolute-button img{
  height: 1.6rem;
  width: 1.6rem;
  filter: var(--c);
}

.back-button .resolute-button img{
  transform: scaleX(-1);
}

.nestedpage-header{
  font-size: 2rem;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  font-size: 2rem;
  font-weight: 900;
  color: #fa2509;
}

}

