@media only screen and (min-width: 576px){

.internship-card-container{
    width: 70.5vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
}

.internship-card-container:not(:first-child){
    margin-top: 2.2vw;
}

.job-card-block{
    width: 34.25vw;
    overflow: hidden;
    
    border: 2px solid #fa2509;
}

.job-title{
    font-size: 1rem;
    font-weight: 900;
    padding: 1.2vw;
    background-color: #fa2509;
}

.job-blocks{
    padding: 0.8vw;
}

.job-block-child{
    background-color: #1E1E1E;
    margin-top: 1vh;
    padding: 1vw;
    font-size: 0.9rem;
}

.job-block-child img{
    width: 1.2vw;
    height: 1.2vw;
    filter: brightness(0) invert(1);
}

.job-block-child{
    display: flex;
    gap: 1vw;
    align-items: center;
}

.internship-button-padding{
    padding: 2vw;
}

.internship-button-margin{
    margin: 0;
}

}

@media only screen and (max-width: 575px){

.internship-card-container{
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.job-card-block{
    width: 90vw;
    overflow: hidden;
    border: 1px solid #fa2509;
}

.job-card-block{
    margin-top: 5vw;
}

.job-title{
    font-size: 1.2rem;
    font-weight: 900;
    padding: 2vh;
    background-color: #fa2509;
}

.job-blocks{
    padding: 2vh;
}

.job-block-child{
    background-color: #1E1E1E;
    margin-top: 1vh;
    padding: 2vh;
}

.job-block-child img{
    width: 5vw;
    height: 5vw;
    filter: brightness(0) invert(1);
}

.job-block-child{
    display: flex;
    gap: 1vh;
    font-size: 0.8rem;
    align-items: center;
}

.internship-button-padding{
    padding: 4vh 2vh;
}

.internship-button-margin{
    margin: 0;
}

}