@media only screen and (min-width: 576px){

.common-header{
    margin-top: 10vh;
    margin-left: 14.5vw;
    margin-right: 14.5vw;
    display: flex;
    font-size: 0.9rem;
    line-height: 1.6rem;
}
    
.common-header .left{
    width: 16vw;
    float: left;
    font-size: 1.6rem;
    font-weight: 900;
    color: #fa2509;
}
    
.common-header .right{
    width: 60vw;
    float: left;
    margin-left: 2vw;
}

.common-header .common-header-single{
    width: 71vw;
    float: left;
    font-size: 1.6rem;
    line-height: 3.6rem;
    font-weight: 900;
    color: #fa2509;
}

.common-header-single-negative-margin{
    margin-top: -5vh;
}
}

@media only screen and (max-width: 575px){
    
.common-header{
    margin-top: 8vh;
    margin-left: 5vw;
    margin-right: 5vw;
}
    
.common-header .left{
    width: 90vw;
    font-size: 2rem;
    font-weight: 900;
    color: #fa2509;
    line-height: 3.2rem;
}
    
.common-header .right{
    width: 90vw;
    font-size: 0.8rem;
    margin-top: 5vh;
    line-height: 1.6rem;
}

.common-header .common-header-single{
    width: 90vw;
    font-size: 2rem;
    line-height: 3.2rem;
    font-weight: 900;
    color: #fa2509;
}
}