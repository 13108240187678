
@media only screen and (min-width: 576px){

.author-container{
    background-size: cover;
    background-repeat: no-repeat;
    width: 70.5vw;
    height: 30vw;
    padding: 1.6vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 10vh;
    margin-bottom: 10vh;
    border: 2px solid #fa2509;
}

.blog-metadata{
    display: flex;
    align-items: center;
    align-self: flex-end;
}

.blog-metadata .author-image{
    width: 10vw;
    height: 10vw;
    border-radius: 5vw;
    border: 2px solid #fa2509;
}

.blog-metadata-container{
    padding-left: 2vw;
    padding-right: 2vw;
}

.blog-metadata-container .upload-date{
    opacity: 0.8;
    font-size: 0.9rem;
    line-height: 1.6rem;
}

.blog-metadata-container .blog-title{
    font-size: 1.4rem;
    font-weight: 900;
}

.blog-metadata-container .about-author{
    display: flex;
    align-items: center;
    margin-top: 0.6rem;
}

.blog-metadata-container .linkedin-btn{
    margin-left: 1.2vw;
    background-color: inherit;
    border: 0;
}

.blog-metadata-container img{
    filter: brightness(0) invert(1);
}

.blog-metadata-container .author-name{
    font-size: 0.9rem;
    font-weight: 900;
}

.blog-view{
    width: 70.5vw;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.9rem;
    /* line-height: 1.8rem; */
    margin-bottom: 10vh;
} 
}

@media only screen and (max-width: 575px){

.author-container{
    background-size: cover;
    background-repeat: no-repeat;
    width: 90vw;
    height: 38.29vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 10vh;
    margin-bottom: 10vh;
    border: 1px solid #fa2509;
}

.blog-metadata{
    align-items: center;
    margin-top: 20vw;
}

.blog-metadata .author-image{
    width: 30vw;
    height: 30vw;
    margin-left: 5vw;
    border-radius: 15vw;
    border: 1px solid #fa2509;
}

.blog-metadata-container{
    padding-left: 0vw;
    padding-right: 0vw;
}

.blog-metadata-container .upload-date{
    margin-top: 3vh;
    opacity: 0.8;
    font-size: 0.8rem;
    line-height: 1.8rem;
}

.blog-metadata-container .blog-title{
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 900;
}

.blog-metadata-container .about-author{
    display: flex;
    align-items: center;
    margin-top: 2vh;
}

.blog-metadata-container .linkedin-btn{
    margin-left: 5vw;
    background-color: inherit;
    border: 0;
}

.blog-metadata-container img{
    filter: brightness(0) invert(1);
}

.blog-metadata-container .author-name{
    font-size: 1rem;
    font-weight: 500;
}

.blog-view{
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.9rem;
    line-height: 1.6rem;
    margin-bottom: 10vh;
    margin-top: 65vw;
}
}
          