@media only screen and (min-width: 576px){
.card-slider-container{
    display: flex;
    width: 70.5vw;
    height: auto;
    margin-top: 6vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

.card-slider-container .flip-card-inner{
    transition: transform 0.6s;
}

.card-slider-container .flip-card {
    background-color: transparent;
    position: relative;
    width: 20vw;
    height: 23vw;
    margin-top: 4vh;
    perspective: 1000px;
}

.card-slider-container .flip-card-front {
    width: 20vw;
    height: 21vw;
    overflow: hidden;
    border: 2px solid #fa2509;
    background-color: #fa2509;
}

.flip-card-front .img-flexing{
    width: 20vw;
    height: 16vw;
}

.card-slider-container .flip-card-front img{
    object-fit: cover;
}

.card-slider-container .flip-card-back {
    background-color: #121212;
    border: 2px solid #fa2509;
    padding: 1.2vw;
    font-size: 0.9rem;
    line-height: 1.6rem;
    width: 20vw;
    height: 21vw;
    transform: rotateY(180deg);
    overflow-y: scroll;
}

.card-heading{
    padding: 1.2vw;
    font-size: 0.9rem;
    font-weight: 900;
    width: 20vw;
    height: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.card-slider-container-double .flip-card {
    height: 22vw;
    margin-bottom: 1vw;
}
    
.card-slider-container-double .inner-container {
    width: 20vw;
    height: auto;
}
}
    
@media only screen and (max-width: 575px){
.card-slider-container{
    width: 90vw;
    height: auto;
    margin-top: 1vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 5vw;
    overflow-x: scroll;
    overflow-y: hidden;
}

.card-slider-container .flip-card-inner{
    transition: transform 0.6s;
}

.card-slider-container .flip-card {
    background-color: transparent;
    width: 42.5vw;
    height: 48.29vw;
    margin-top: 4vh;
    perspective: 1000px;
}

.card-slider-container .flip-card-front {
    width: 42.5vw;
    height: 44.43vw;
    overflow: hidden;
    border: 1px solid #fa2509;
    background-color: #fa2509;
}

.flip-card-front .img-flexing{
    width: 42.5vw;
    height: 34.77vw;
}

.card-slider-container .flip-card-front img{
    object-fit: cover;
}

.card-slider-container .flip-card-back {
    background-color: #121212;
    border: 1px solid #fa2509;
    padding: 1.2vw;
    line-height: 1.8rem;
    width: 42.5vw;
    height: 44.43vw;
    transform: rotateY(180deg);
    overflow-y: scroll;
}

.card-heading{
    padding: 1.2vw;
    font-weight: 900;
    width: 42.5vw;
    height: 9.66vw;
    font-size: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.card-slider-container-double .flip-card {
    height: 42vw;
    margin-bottom: 3vh;
}
    
.card-slider-container-double .inner-container {
    width: 42.5vw;
    height: auto;
}     
}