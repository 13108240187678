@media only screen and (min-width: 576px){
.aips{
    width: 70.5vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
}

.aips-row{
    margin-top: 2vw;
    display: flex;
    gap: 2vw;

}

.aips .aips-image{
    float: left;
    width: 34.25vw;
    height: 20vw;
    overflow: hidden;
    background-color: #fa2509;
    background-size: cover;
}

.aips .aips-description{
    float: left;
    width: 34.25vw;
    height: 20vw;
    overflow-y: scroll;
    padding-top: 1.2vw;
    font-size: 0.9rem;
    line-height: 1.6rem;
}

.aips .approach-image{
    width: 34.25vw;
    height: 20vw;
    border: 2px solid #fa2509;
    display: flex;
    justify-content: left;
    align-items: left;
    padding: 1.6vw;
}

.aips .approach-name{
    font-size: 1.4rem;
    font-weight: 900;
}
.aips-row-mob{
    display: none;
}

.mob-space{
    display: none;
}
}

@media only screen and (max-width: 575px){
.aips{
    width: 90vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.aips .aips-image{
    float: left;
    width: 90vw;
    height: 57.81vw;
    overflow: hidden;
    background-color: #fa2509;
    margin-top: 5vh;
}

.aips .aips-description{
    float: left;
    width: 90vw;
    height: auto;
    overflow-y: hidden;
    padding-top: 1.2vw;
    font-size: 0.8rem;
    line-height: 1.6rem;
    margin-top: 2vh;
}

.aips .approach-image{
    width: 90vw;
    height: 57.81vw;
    border: 1px solid #fa2509;
    display: flex;
    justify-content: left;
    align-items: left;
    padding: 5vw;
}

.aips .approach-name{
    font-size: 1.2rem;
    line-height: 2.4rem;
    font-weight: 900;
}

.aips-row-desktop{
    display: none;
}

.mob-space{
    height: 0.1vw;
    width: 100vw;
    background-color: inherit;
}
}