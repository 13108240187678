@media only screen and (min-width: 576px) {
    * {
        color: #ffffff;
    }

    .mission-banner-container {
        width: 70.5vw;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        margin-top: 10vh;
    }

    .mission-banner-image {
        width: 34.25vw;
        height: 24vw;
        border: 2px solid #fa2509;

    }

    .mission-description {
        width: 34.25vw;
        height: 24vw;
        margin-left: 2vw;
        padding: 1vw 0;
        font-size: 0.9rem;
        line-height: 1.6rem;
        /* font-weight: bold; */
        display: flex;
        align-items: center;
    }

    .our-values-container {
        width: 70.5vw;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;

    }

    .values-banner-image {
        width: 22.16vw;
        height: 24vw;
        border: 2px solid #fa2509;
        overflow: hidden;
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.6) 61.64%, rgba(0, 0, 0, 0) 100%);
        border: 1px solid #F33823;
    }

    .value-overlay-div {
        background: rgba(0, 0, 0, 0.4);
        width: 22.16vw;
        height: 24vw;
        margin: 0;
        padding: 2vw 1vw 1vw 1vw;
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.6) 61.64%, rgba(0, 0, 0, 0) 100%);
    }

    .values-banner-image p {
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-weight: bolder;
    }

    .values-banner-image .value-text-description {
        font-size: 0.9rem;
        line-height: 1.6rem;
        font-weight: 600;
        margin-top: 2rem;
        color: #ffffff;
    }

}

@media only screen and (max-width: 575px) {

    .mission-banner-container {
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5vh;
    }

    .mission-banner-image {
        width: 90vw;
        height: 45vw;
        border: 1px solid #fa2509;
    }

    .mission-description {
        width: 90vw;
        padding: 5vh 0 0 0;
        font-size: 0.8rem;
        line-height: 1.6rem;
        font-weight: bold;
    }

    .our-values-container {
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        gap: 5vw;
    }

    .values-banner-image {
        width: 90vw;
        height: 60vw;
        border: 1px solid #fa2509;
        overflow: hidden;
    }

    .value-overlay-div {
        background: rgba(0, 0, 0, 0.4);
        width: 90vw;
        height: 60vw;
        margin: 0;
        padding: 2vh 1vh 1vh 1vh;
    }

    .values-banner-image p {
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-weight: bolder;
    }

    .values-banner-image .value-text-description {
        font-size: 0.8rem;
        line-height: 1.6rem;
        font-weight: 600;
        margin-top: 2rem;
    }
}