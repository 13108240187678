@media only screen and (min-width: 576px){

.back-button{
    margin-bottom: 10vh;
}

.toggleCategory{
    margin-left: 14vw;
}

.videos-container{
    display: flex;
    width: 70.5vw;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 2.2vw;
}

.video-card-container{
    width: 34.25vw;
    overflow: hidden;
    border: 2px solid #fa2509;
}
.video-card-image{
    width: 34.25vw;
    height: 19.13vw;
    border-bottom: 2px solid #fa2509;
}

.video-card-heading{
    font-size: 1rem;
    line-height: 1.8rem;
    height: 5rem;
    padding: 2vh;
    font-weight: 900;
}

.video-card-description{
    font-size: 0.8rem;
    line-height: 1.4rem;
    padding: 0vh 2vh 2vh 2vh;
    height: 5rem;
}


}

@media only screen and (max-width: 575px){

    .toggleCategory{
        margin-left: 25px;
    }

.back-button{
    margin-bottom: 5vh;
}

.videos-container{
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.video-card-container{
    width: 90vw;
    overflow: hidden;
    border: 1px solid #fa2509;
    margin-top: 5vw;
}

.video-card-image{
    width: 90vw;
    height: 50.63vw;
    border-bottom: 1px solid #fa2509;
}

.video-card-heading{
    font-size: 1rem;
    line-height: 1.8rem;
    height: 5rem;
    padding: 2vh;
    font-weight: 900;
}

.video-card-description{
    font-size: 0.8rem;
    line-height: 1.4rem;
    padding: 0vh 2vh 2vh 2vh;
    height: 8rem;
    opacity: 0.9;
}


}
    