@media only screen and (min-width: 576px){

.descriptive-header-text{
    font-size: 0.9rem;
    /* line-height: 1.6rem; */
    width: 70.5vw;
    margin-left: auto;
    margin-right: auto;
}

}

@media only screen and (max-width: 575px){

.descriptive-header-text{
    font-size: 0.8rem;
    line-height: 1.6rem;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

}
