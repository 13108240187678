@media screen and (min-width: 971px) {

nav{
position: fixed;
z-index: 99;
width: 100%;
background: #ffffff;
top: 35px;
}
nav .wrapper{
position: relative;
max-width: 98vw;
padding: 0px 30px;
height: 70px;
line-height: 70px;
margin: auto;
display: flex;
align-items: center;
justify-content: space-between;
}

.mob-logo{
    display: none;
}
.wrapper .logo{
     position: relative;
}

.wrapper .logo a{
text-decoration: none;
}
.wrapper .logo a img{
height: 65px;
width: auto;
margin-bottom: 1px;
}
.wrapper .nav-links{
display: inline-flex;
margin: 0;
}
.nav-links li{
list-style: none;
}
.nav-links li a{
color: #000000;
text-decoration: none;
font-size: 0.9rem;
font-weight: 900;
padding: 0.8rem 2rem;
transition: all 0.3s ease;
}
a{
    text-decoration: none;
}


.nav-links li a:hover{
background-color: #ffffff;
font-weight: 900;
color: #F33823;
}

.nav-links .main-menu-button:hover{
color: #F33823;
border-bottom: 5px solid #F33823;
background-color: #ffffff;
}
.nav-links .mobile-item{
display: none;
}
.nav-links .drop-menu{
position: absolute;
padding: 0;
background: #ffffff;
width: 180px;
line-height: 45px;
top: 85px;
opacity: 0;
visibility: hidden;
box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
transition: all 0.3s ease;
top: 70px;
opacity: 1;
visibility: visible;
}
.drop-menu li a{
width: 100%;
display: block;
padding: 0 0 0 15px;
font-weight: 400;
border-radius: 0px;
}
.mega-box{
position: absolute;
left: 0;
width: 100%;
padding: 0 30px;
top: 85px;
opacity: 0;
visibility: hidden;
}

.content{
background: #ffffff;
padding: 25px 20px;
display: flex;
width: 100%;
justify-content: space-between;
align-items: flex-start;
box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}

.products-content{
background: #ffffff;
padding: 25px 20px;
margin-left: 50%;
display: flex;
width: 50%;
gap: 6vw;
justify-content: center;
align-items: flex-start;
box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}

.services-content{
background: #ffffff;
padding: 25px 20px;
margin-left: 50%;
display: flex;
width: 50%;
gap: 6vw;
justify-content: center;
align-items: flex-start;
box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}

.content .row{
width: calc(25% - 30px);
}

.products-content .row{
width: calc(50% - 80px);
}

.services-content .row{
width: calc(50% - 30px);
}

.content .row img{
width: 100%;
height: 100%;
object-fit: cover;
}

.content .row .mega-links{
margin-left: -40px;
border-left: 1px solid rgba(0,0,0,0.09);
}

.content .row .mega-links img{
width: 1.6rem;
height: 1.6rem;
filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
brightness(104%) contrast(97%);
}

.row .mega-links li{
padding: 0 20px;
}
.row .mega-links li a{
padding: 0px;
padding: 0 20px;
line-height: 1.6rem;
margin-top: 1.6rem;
color: #000000;
font-size: 0.9rem;
display: flex;
gap: 1.8rem;
align-items: center;

}

.row .mega-links li a:hover{
color: #F33823;
font-weight: 900;

}

.nav-card-heading img{
width: 1.2rem;
height: 1.2rem;
filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
brightness(104%) contrast(97%);
}

.nav-card-heading{
background-color: #ffffff;
width: 100%;
border: 0;
padding: 25px 0;
color: #F33823;
font-size: 1rem;
font-weight: 400;
line-height: 1.8rem;
text-align: center;
display: flex;
gap: 0.6rem;
justify-content: center;
align-items: center;
}

.nav-card-heading:hover{
font-weight: 900;
gap: 1.2rem;
}

.nav-card-heading-products{
width: 50%;
margin-left: 50%;
}

.wrapper .btn{
color: #000000;
font-size: 20px;
cursor: pointer;
display: none;
}
.wrapper .btn.close-btn{
position: absolute;
right: 30px;
top: 10px;
}
.PartnerWithUs{
color: #f33823;
background-color: transparent;
font-weight: normal;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 1rem 2rem;
line-height: 1.2rem;
font-size: 0.9rem;
font-weight: 900;
gap: 0.6rem;
border: 2px solid #f33823;
border-radius: 27.25px;
--c: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
height: 55.2px;
margin-top: 7.5px;
}

.PartnerWithUs:hover {
gap: 1.8rem;
background-color: #f33823;
color: #ffffff;
--c: brightness(0) invert(1);
}
.PartnerWithUs img{
height: 1.6rem;
width: 1.6rem;
filter: var(--c);
}
}



@media screen and (max-width: 970px) {


.close-btn{
    text-align: right;
}

nav{
position: fixed;
z-index: 99;
width: 100%;
background: #040404;
top: 0px;
}

nav .wrapper{
position: relative;
padding: 0px 5vw;
height: 70px;
line-height: 70px;
margin: auto;
display: flex;
align-items: center;
justify-content: space-between;
}

.logo{
    display: none;
}

.wrapper .mob-logo a{
text-decoration: none;
}

.wrapper .mob-logo a img{
height: 30px;
width: auto;
}

.wrapper .btn{
    display: block;
}

.menu-btn{
    padding: 0;
}

.menu-button{
    width: 30px;
    height: 30px;
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.close-button{
    width: 30px;
    height: 30px;
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.wrapper .nav-links{
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: -100%;
    background: #040404;
    display: block;
    padding: 20px 5vw;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
}
/* custom scroll bar */
::-webkit-scrollbar {
    width: 2vw;
}
::-webkit-scrollbar-track {
    background: #242526;
}
::-webkit-scrollbar-thumb {
    background: #3A3B3C;
}
#menu-btn:checked ~ .nav-links{
    left: 0%;
}
#menu-btn:checked ~ .btn.menu-btn{
    display: none;
}
#close-btn:checked ~ .btn.menu-btn{
    display: block;
}
.nav-links li{
    margin: 16px 0px;
    list-style: none;
}
.nav-links li a{
    padding: 16px 20px;
    display: block;
    font-size: 0.8rem;
}
.nav-links .drop-menu{
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
}
#showDrop:checked ~ .drop-menu,
#showMegaProducts:checked ~ .mega-box-products,
#showMegaSolutions:checked ~ .mega-box-solutions,
#showMegaServices:checked ~ .mega-box-services,
#showMegaAbout:checked ~ .mega-box-about{
    max-height: 100%;
}
.nav-links .desktop-item{
    display: none;
}
.nav-links .mobile-item{
    display: block;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bolder;
    font-weight: 500;
    padding: 2px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.nav-links .mobile-item:hover{
    background: #161616;
}

.nav-card-heading{
    font-size: 0.9rem;
    border: 0;
    background-color: inherit;
    display: flex;
    align-items: center;
    gap: 2vw;
    margin-top: 2vh;
    color: #F33823;
}

.products-content{
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 4vh;
    align-items: center;
    padding: 2vh 0;
}

.submenu-image{
    display: none;
}

.nav-card-heading img{
    width: 1.2rem;
    height: 1.2rem;
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.solutions-content .mega-links a, .services-content .mega-links a, .about-content .mega-links a{
    padding: 0;
    font-size: 0.9rem;
    margin: 2rem 0rem 2rem 1rem;
    line-height: 1.6rem;
    text-decoration: none;
    color: #f2f2f2;
    display: flex;
    gap: 4vw;
    align-items: center;
    opacity: 0.8;
}

.solutions-content img, .services-content img, .about-content img{
    width: 1.2rem;
    height: 1.2rem;
    filter: brightness(0) invert(1);
}

.mega-box{
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 5vw;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
}
.mega-box .content{
    box-shadow: none;
    flex-direction: column;
}

.mega-box .content .row:nth-child(1),
.mega-box .content .row:nth-child(2){
    border-top: 0px;
}

.row .mega-links li{
    margin: 0;
}
.PartnerWithUs{
color: #f33823;
background-color: transparent;
font-weight: normal;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 1rem 2rem;
line-height: 1.2rem;
font-size: 0.9rem;
font-weight: 900;
gap: 0.6rem;
border: 2px solid #f33823;
border-radius: 27.25px;
--c: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
height: 55.2px;
margin-top: 5vh;
}

.PartnerWithUs:hover {
gap: 1.8rem;
background-color: #f33823;
color: #ffffff;
--c: brightness(0) invert(1);
}
.PartnerWithUs img{
height: 1.6rem;
width: 1.6rem;
filter: var(--c);
}

}
nav input{
display: none;
}


