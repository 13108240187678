@media only screen and (min-width: 576px){
.CoreTeam_mainContainer{
    background-color: green;
   
    background: radial-gradient(66.32% 66.32% at 50% 50%, rgba(243, 56, 35, 0.18) 0%, rgba(243, 56, 35, 0) 100%);
}
.core-team-container{
    width: 70.5vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
}

.core-team-container:not(:first-child){
    margin-top: 2.3vw;
}

.team-card{
    width: 34.25vw;
    border: 2px solid #fa2509;
    overflow: hidden;
    background: linear-gradient(to right, #121212 50%, #fa2509 50%);
    /* background: transparent; */
    background-size: 200% 100%;
    background-position:left bottom;
    transition:all 1s ease;
}

.team-card:hover {
    background-position:right bottom;
}

.team-card-sub-container{
    padding: 1vw;
    display: flex;
}

.team-card-image{
    width: 14vw;
    height: 14vw;
}

.team-card-text{
    margin-left: 1vw;
    width: 17.25vw;
}

.team-card-name{
    margin-top: 4vh;
    font-size: 1.4rem;
    font-weight: bolder;
}

.team-card-designation{
    margin-top: 1vh;
    font-size: 0.9rem;
    line-height: 1.6rem;
    height: 5vw;
}

.team-card-text .team-card-linkedin-btn{
    background-color: inherit;
    border: 0;
    filter: brightness(0) invert(1);
    width: 100%;
    text-align: end;
}

.team-card-text .team-card-linkedin-btn img{
    width: 3vw;
    height: 3vw;
}
}

@media only screen and (max-width: 575px){

.core-team-container{
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
}

.core-team-container:not(:first-child){
    margin-top: 6vw;
}

.team-card{
    width: 42.5vw;
    border: 1px solid #fa2509;
    overflow: hidden;
    background: linear-gradient(to right, #121212 50%, #fa2509 50%);
    background-size: 200% 100%;
    background-position:left bottom;
    transition:all 1s ease;
    color: #ffffff;
}

.team-card:hover {
    background-position:right bottom;
}

.team-card-sub-container{
    padding: 2vw;
    display: flex;
    flex-direction: column;
}

.team-card-image{
    width: 38.5vw;
    height: 38.5vw;
}

.team-card-name{
    margin-top: 2vw;
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: bolder;
}

.team-card-designation{
    font-size: 0.8rem;
    line-height: 1.2rem;
    margin-top: 1vw;
    height: 3.6rem;
}

.team-card-text .team-card-linkedin-btn{
    background-color: inherit;
    border: 0;
    filter: brightness(0) invert(1);
    width: 100%;
    text-align: end;
}

.team-card-text .team-card-linkedin-btn img{
    width: 8vw;
    height: 8vw;
}
}