@media only screen and (min-width: 576px){
  .container {
    position: relative;
    border: 2px solid #fa2509;
    width: 70.5vw;
    overflow: hidden;
    padding-top: 39.38vw;
    margin-top: 10vh;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
  
@media only screen and (max-width: 575px){
  .container {
    position: relative;
    border: 1px solid #fa2509;
    width: 90vw;
    overflow: hidden;
    padding-top: 50.27vw;
    margin-top: 5vh;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }    

}