@media only screen and (min-width: 576px){

.blogs-card-slider-container{
    width: 70.5vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: hidden;
}

.blogs-card-slider-container .flip-card-inner{
    transition: transform 0.6s;
}

.blogs-card-slider-container .flip-card {
    background-color: transparent;
    width: 22vw;
    height: 27vw;
    margin-top: 2vh;
    perspective: 1000px;
}

.blogs-card-slider-container .flip-card-front {
    width: 22vw;
    height: 25vw;
    overflow: hidden;
    border: 2px solid #fa2509;
}

.blogs-card-slider-container .flip-card-front img{
    object-fit: cover;
}

.blog-cover-sizing{
    width: 22vw;
    height: 10vw;
}

.blogs-card-slider-container .flip-card-back {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fa2509;
    padding: 1.2vw;
    line-height: 1.8rem;
    width: 22vw;
    height: 25vw;
    transform: rotateY(180deg);
}

.blogs-card-slider-container .flip-card-front .upload-date{
    padding: 1.2vw 1.2vw 0vw 1.2vw;
    font-size: 0.8rem;
    opacity: 0.8;
}

.blogs-card-slider-container .flip-card-front .blog-title{
    padding: 0.6vw 1.2vw;
    font-size: 0.9rem;
    line-height: 1.6rem;
}

.blogs-card-slider-container .flip-card-front .author, .author-data{
    display: flex;
    align-items: center;
}

.blogs-card-slider-container .flip-card-front .bottom{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.blogs-card-slider-container .flip-card-front .author{
    padding: 0.6vw 1.2vw 1.2vw 1.2vw;
    justify-content: space-between;
}

.blogs-card-slider-container .flip-card-front .author-image{
    border-radius: 1.5vw;
    border: 2px solid #fa2509;
    width: 2.4vw;
    height: 2.4vw;
}

.blogs-card-slider-container .flip-card-front .author-name{
    padding: 0vw 0.6vw;
    font-size: 0.8rem;
}

.blogs-card-slider-container .flip-card-front .linkedin-btn{
    background-color: inherit;
    border: 0;
    filter: brightness(0) invert(1);
    width: 2.4vw;
    height: 2.4vw;
}

.blogs-card-slider-container .flip-card-back .resolute-button{
    margin: 0;
    justify-content: center;
    padding: 0.8rem 1.6rem;
    gap: 0.6rem;
    height: fit-content;
    width: fit-content;
    font-size: 0.9rem;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #fa2509;
    color: #ffffff;
    --c: brightness(0) invert(1);
}

.blogs-card-slider-container .flip-card-back .resolute-button img{
    height: 1.2rem;
    width: 1.2rem;
}
    
}

@media only screen and (max-width: 575px){

.blogs-card-slider-container{
    width: 90vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
    overflow-y: hidden;
}

.blogs-card-slider-container .flip-card-inner{
    transition: transform 0.6s;
}

.blogs-card-slider-container .flip-card {
    background-color: transparent;
    width: 90vw;
    height: 95vw;
    margin-top: 2vh;
    perspective: 1000px;
}

.blogs-card-slider-container .flip-card-front {
    width: 90vw;
    height: 90vw;
    overflow: hidden;
    border: 1px solid #fa2509;
}

.blogs-card-slider-container .flip-card-front img{
    object-fit: cover;
}

.blog-cover-sizing{
    width: 90vw;
    height: 40vw;
}

.blogs-card-slider-container .flip-card-back {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fa2509;
    padding: 1.2vw;
    line-height: 1.8rem;
    width: 90vw;
    height: 102vw;
    transform: rotateY(180deg);
}

.blogs-card-slider-container .flip-card-front .upload-date{
    padding: 5vw 5vw 0vw 5vw;
    font-size: 0.6rem;
    opacity: 0.8;
}

.blogs-card-slider-container .flip-card-front .blog-title{
    padding: 2vw 5vw;
    font-size: 0.8rem;
    line-height: 1.6rem;
}

.blogs-card-slider-container .flip-card-front .author, .author-data{
    display: flex;
    align-items: center;
}

.blogs-card-slider-container .flip-card-front .bottom{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.blogs-card-slider-container .flip-card-front .author{
    padding: 2vw 5vw 5vw 5vw;
    justify-content: space-between;
}

.blogs-card-slider-container .flip-card-front .author-image{
    border-radius: 5vw;
    border: 1px solid #fa2509;
    width: 10vw;
    height: 10vw;
}

.blogs-card-slider-container .flip-card-front .author-name{
    padding: 0vw 2vw;
    font-size: 0.8rem;
}

.blogs-card-slider-container .flip-card-front .linkedin-btn{
    background-color: inherit;
    border: 0;
    filter: brightness(0) invert(1);
    width: 10vw;
    height: 10vw;
}

.blogs-card-slider-container .flip-card-back .resolute-button{
    margin: 0;
    justify-content: center;
    padding: 0.8rem 1.6rem;
    gap: 0.6rem;
    height: fit-content;
    width: fit-content;
    font-size: 0.9rem;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #fa2509;
    color: #ffffff;
    --c: brightness(0) invert(1);
}

.blogs-card-slider-container .flip-card-back .resolute-button img{
    height: 1.2rem;
    width: 1.2rem;
}

}
      