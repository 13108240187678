@media only screen and (min-width: 576px){
.display-banner{
    border: 2px solid #fa2509;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16vh;
    width: 70.5vw;
    height: 24vw;
    overflow: hidden;
}

.display-banner p{
    margin: 0;
    font-size: 2.6rem;
    font-weight: 900;
}

.display-banner .overlay-div{
    margin: 0;
    background:rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.display-banner .text-description{
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 1rem 2rem 0 2rem;
    font-weight: bold;
}
}

@media only screen and (max-width: 575px){
.display-banner{
    border: 1px solid #fa2509;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8vh;
    width: 90vw;
    height: 45vw;
    overflow: hidden;
}

.display-banner p{
    margin: 0;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 900;
}

.display-banner .overlay-div{
    margin: 0;
    background:rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.display-banner .text-description{
    font-size: 1rem;
    line-height: 1.8rem;
    padding: 1rem 1rem 0 1rem;
    font-weight: bold;
}
}