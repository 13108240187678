@media only screen and (min-width: 576px){


.footer-heading-left{
    margin-top: 12vh;
}

.contact-form-banner-mobile{
    display: none;
}

.footer-container{
    width: 70.5vw;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
    display: flex;
    gap: 2vw;
}

.footer-container-left, .footer-container-right{
    width: 34.25vw;
}

.footer-form input{
    width: 100%;
}

.footer-form .two-sided-fields{
    display: flex;
    gap: 1rem;
}

.footer-form .two-sided-fields div{
    flex-basis: 100%;
}

.footer-form input{
    background-color: inherit;
    color:rgba(255,255,255);
    font-size: 0.9rem;
    line-height: 1.8rem;
    border: 2px solid #fa2509;
    padding: 0.5rem;
    margin-top: 1rem;
}

.footer-form small{
   color: red;
   font-size: 12px;
}

.footer-form input::placeholder{
    font-size: 0.9rem;
    line-height: 1.8rem;
    color:rgba(255,255,255, 0.5);
}

.footer-form .form-submit-btn{
    width: auto;
    color: #FFFFFF;
    background-color: #fa2509;
    font-weight: normal;
    display: flex;
    margin-top: 4vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    gap: 0.6rem;
    border: 2px solid #fa2509;
    border-radius: 40px;
}

.contact-form-banner-desktop{
    width: 34.25vw;
    margin-top: 1rem;
    height: 22.3rem;
    border: 2px solid #fa2509;
}

}

.helpContainer{
    display: flex;
    row-gap: 10px;
    column-gap: 15px;
    width: 100%;
    flex-wrap: wrap;
    height: 80%;
}

.helpContainer .card{
    background-color: #F33823;
    flex-basis: 47%;
    height: 50%;
    padding: 10px 25px;
    align-items: center;
}
.helpContainer .card .helpText{
    position: absolute;
    align-items: center;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

.helpContainer .card .helpText h6{
   font-size: 14px;
}

.helpContainer .card .icons{
    position: absolute;
    left: 10px;
   
}

@media only screen and (max-width: 575px){  
    
.helpContainer{
    /* flex-wrap: wrap; */
    display: none;
}

.footer-heading-left{
    margin-top: 10vh;
}

.contact-form-banner-desktop{
    display: none;
}

.footer-container{
    width: 90vw;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
}

.footer-container-left, .footer-container-right{
    width: 90vw;
}

.footer-form input{
    width: 100%;
}

.footer-form .two-sided-fields{
    display: flex;
    gap: 5vw;
}
.footer-form input{
    background-color: inherit;
    color:rgba(255,255,255);
    font-size: 1rem;
    line-height: 1.6rem;
    border: 1px solid #fa2509;
    padding: 0.8rem;
    margin-top: 5vw;
}

.footer-form input::placeholder{
    font-size: 0.8rem;
    line-height: 1.6rem;
    color:rgba(255,255,255, 0.5);
}

.footer-form .form-submit-btn{
    width: auto;
    color: #FFFFFF;
    background-color: #fa2509;
    font-weight: normal;
    display: flex;
    margin-top: 4vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    gap: 0.6rem;
    border: 1px solid #fa2509;
    border-radius: 40px;
}

.contact-form-banner-mobile{
    width: 90vw;
    margin-bottom: 2vh;
    height: 60vw;
    border: 1px solid #fa2509;
}

}