@media only screen and (min-width: 576px){

.AnalyticsKart{
    margin-top: 10vh;
    margin-left: 14.5vw;
    margin-right: 14.5vw;
    display: flex;
    font-size: 0.9rem;
    line-height: 1.6rem;
}

.AnalyticsKart{
    margin-top: 20vh;
}

.AnalyticsKart .left{
    width: 16vw;
    float: left;
}

.AnalyticsKart .left img{
    width: 12vw;
}

.AnalyticsKart .right{
    width: 60vw;
    float: left;
    margin-left: 2vw;
}
}

@media only screen and (max-width: 575px){
  
.AnalyticsKart{
    margin-top: 6vh;
    margin-left: 5vw;
    margin-right: 5vw;
    font-size: 0.8rem;
    line-height: 1.6rem;
}

.AnalyticsKart{
    margin-top: 10vh;
}

.AnalyticsKart .left{
    width: 90vw;
}

.AnalyticsKart .left img{
    width: 50vw;
    margin-bottom: 5vh;
}

.AnalyticsKart .right{
    width: 90vw;
}
}