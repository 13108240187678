@media only screen and (min-width: 576px){

.verticle-list-mob{
    display: none;
}

.verticle-list{
    width: 70.5vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
}

.verticle-list-row{
    display: flex;
    gap: 2.15vw;
    margin-top: 2.15vw;
}

.verticle-card {
    background-color: transparent;
    width: 16vw;
    height: 18vw;
    perspective: 1000px;
    float: left;
}

.verticle-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0s;
    transform-style: preserve-3d;
}

.verticle-card:hover .verticle-card-inner {
    transform: rotateY(180deg);
}

.verticle-card-front, .verticle-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.verticle-card-front {
    overflow: hidden;
    object-fit: contain;
    border: 2px solid #fa2509;
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.6rem;
    padding: 1.2vw;
}

.verticle-card-back {
    background-color: #121212;
    border: 2px solid #fa2509;
    transform: rotateY(180deg);
    overflow: hidden;
}

.verticle-card-back .overlay-div{
    background:rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.verticle-card-back .resolute-button{
    margin: 0;
    justify-content: center;
    padding: 0.8rem 1.6rem;
    gap: 0.6rem;
    height: fit-content;
    width: fit-content;
    font-size: 0.9rem;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #fa2509;
    color: #ffffff;
    --c: brightness(0) invert(1);
}

.verticle-card-back .resolute-button img{
    height: 1.2rem;
    width: 1.2rem;
}
}

@media only screen and (max-width: 575px){

.verticle-list{
    display: none;
}

.verticle-list-mob{
    width: 90vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
}

.verticle-list-row{
    display: flex;
    gap: 5vw;
    margin-top: 5vw;
}

.verticle-card {
    background-color: transparent;
    width: 42.5vw;
    height: 47.81vw;
    perspective: 1000px;
    float: left;
}

.verticle-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0s;
    transform-style: preserve-3d;
}

.verticle-card:hover .verticle-card-inner {
    transform: rotateY(180deg);
}

.verticle-card-front, .verticle-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.verticle-card-front {
    overflow: hidden;
    object-fit: contain;
    border: 1px solid #fa2509;
    font-weight: 700;
    padding: 2.4vw;
    font-size: 0.8rem;
}

.verticle-card-back {
    background-color: #121212;
    border: 1px solid #fa2509;
    transform: rotateY(180deg);
    overflow: hidden;
}

.verticle-card-back .overlay-div{
    background:rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.verticle-card-back .resolute-button{
    margin: 0;
    justify-content: center;
    padding: 0.8rem 1.2rem;
    gap: 0.4rem;
    height: fit-content;
    width: fit-content;
    font-size: 0.8rem;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #fa2509;
    color: #ffffff;
    --c: brightness(0) invert(1);
}

.verticle-card-back .resolute-button img{
    height: 1.2rem;
    width: 1.2rem;
}
}