@media only screen and (min-width: 576px){

.landing_slider .mobile-slider{
	display: none;
}

.landing_slider .carousel-caption{
	position: absolute;
	left: 14vw;
	text-align: start;
	padding-bottom: 12vh;
}

.carousel-caption h4{
	font-size: 28px;
	margin-top: 30px;
	margin-bottom: 0;
	font-weight: 500;
}

.landing_slider .carousel-caption h5{
	font-size: 1.6rem;
	font-weight: bolder;
}

.landing_slider .slider-logo{
	height: 3rem;
	margin-bottom: 1rem;
	width: auto;
}

.landing_slider .same-Row{
	display: flex;
	align-items: center;
	gap: 1rem;
}

.landing_slider .carousel-caption p{
	font-size: 0.9rem;
	width: 36vw;
	font-weight: 600;
	color: #ffffff;
	padding-top: 2vh;
}

.landing_slider .slider-buttons{
	padding-top: 4vh;
}

.same-Row{
	display: flex;
   flex-direction: column;
   position: absolute;
   width: 110%;
   top: -120%;
   left: 50%;
   transform: translate(-50%);
}

.same-Row h1{
	font-weight: 700;
}

.landing_slider .primary-button{
	background-color: #fa2509;
	color: #ffffff;
	float: left;
	border-radius: 40px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1rem 2rem;
	margin-right: 2rem;
	gap: 0.6rem;
	border: 2px solid #fa2509;
	--c: brightness(0) invert(1);
}

.landing_slider .primary-button:hover{
	color: #ffffff;
	background-color: transparent;
	gap: 1.8rem;
	border: 2px solid #ffffff;
	--c: brightness(0) invert(1);
}

.landing_slider a{
	color: white;
}

.landing_slider .primary-button img{
	height: 1.6rem;
	width: 1.6rem;
	filter: var(--c);
}

.landing_slider .secondary-button{
	color: #ffffff;
	background-color: transparent;
	float: left;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1rem 2rem;
	gap: 0.6rem;
	border: 2px solid #ffffff;
	border-radius: 40px;
	--c: brightness(0) invert(1);
}

.landing_slider .secondary-button:hover{
	gap: 1.8rem;
	background-color: #fa2509;
	color: #ffffff;
	border: 2px solid #fa2509;
	--c: brightness(0) invert(1);
}

.landing_slider .secondary-button img{
	height: 1.6rem;
	width: 1.6rem;
	filter: var(--c);
}

}

@media only screen and (min-device-width: 576px) and (max-device-width: 971px){

.landing_slider .mobile-slider{
	display: none;
}

.landing_slider .carousel-caption{
	position: absolute;
	left: 14vw;
	text-align: start;
	padding-bottom: 6vh;
}

.landing_slider .carousel-caption h5{
	font-size: 2rem;
	font-weight: bolder;
}

.landing_slider .slider-logo{
	height: 3.4rem;
	margin-bottom: 1rem;
	width: auto;
}

.landing_slider .same-Row{
	display: flex;
	align-items: center;
	gap: 1rem;
}

.landing_slider .carousel-caption p{
	font-size: 0.9rem;
	width: 100%;
	font-weight: 900;
	line-height: 1.6rem;
	padding-top: 0.6vh;
}

.landing_slider .slider-buttons{
	padding-top: 1vh;
}

	
}

@media only screen and (max-width: 575px){

.landing_slider .desktop-slider{
	display: none;
}

.landing_slider .carousel-caption{
	position: absolute;
	width: 90vw;
	left: 5vw;
	right: 5vw;
	text-align: left;
	padding-bottom: 10vh;
}

.landing_slider .carousel-caption h5{
	font-size: 1.6rem;
	font-weight: bolder;
	padding-top: 0.5rem;
}

.landing_slider .slider-logo{
	height: 3rem;
	margin-left: auto;
	margin-right: auto;
	width: auto;
}

.landing_slider .same-Row{
	display: flex;
	align-items: center;
	gap: 1rem;
}

.landing_slider .same-Row img{
	padding: 0;
	margin: 0;
}

.landing_slider .carousel-caption p{
	font-size: 0.9rem;
	font-weight: 900;
	line-height: 1.6rem;
	padding-top: 4vh;
}

.landing_slider .slider-buttons{
	padding-top: 2vh;
	display: flex;
	gap: 1rem;
}

.landing_slider .carousel-control-next{
	display: none;
}

.landing_slider .carousel-control-prev{
	display: none;
}

.landing_slider .primary-button{
	background-color: #fa2509;
	color: #ffffff;
	border-radius: 40px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 0.8rem;
	padding: 1rem 1.2rem;
	gap: 0.6rem;
	border: 1px solid #fa2509;
	--c: brightness(0) invert(1);
}

.landing_slider .primary-button:hover{
	color: #ffffff;
	background-color: transparent;
	gap: 0.6rem;
	border: 1px solid #ffffff;
	--c: brightness(0) invert(1);
}

.landing_slider .primary-button img{
	height: 1.2rem;
	width: 1.2rem;
	filter: var(--c);
}

.landing_slider .secondary-button{
	color: #ffffff;
	background-color: transparent;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 0.8rem;
	padding: 1rem 1.2rem;
	gap: 0.6rem;
	border: 1px solid #ffffff;
	border-radius: 40px;
	--c: brightness(0) invert(1);
}

.landing_slider .secondary-button:hover{
	gap: 0.6rem;
	background-color: #fa2509;
	color: #ffffff;
	border: 1px solid #fa2509;
	--c: brightness(0) invert(1);
}

.landing_slider .secondary-button img{
	height: 1.2rem;
	width: 1.2rem;
	filter: var(--c);
}
}
		