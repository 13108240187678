@media only screen and (min-width: 576px) {

    .AI-intern-testimonial {
        width: 70.5vw;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5vh;
    }

    .testVdoCard{
        width: 100%;
        display: flex;
        margin-top: 40px;
    }

    .testVdoCard iframe{
        height: 340px;
        width: 600px;
        margin: auto;
    }

    .AI-intern-testimonial .carousel-blank-content {
        width: 100%;
        height: auto;
        padding: 2vw 10vw 5vw 10vw;
    }

    .AI-intern-testimonial .testimonial-card-top {
        display: flex;
        justify-content: space-between;
    }

    .AI-intern-testimonial .intern-details {
        margin-bottom: 2vh;
        display: flex;
        gap: 2vw;
        align-items: center;
    }

    .AI-intern-testimonial .intern-details-name {
        font-size: 1rem;
        margin-bottom: 0.6vh;
        line-height: 1.8rem;
        font-weight: 900;
    }

    .AI-intern-testimonial .intern-details-designation {
        font-size: 0.9rem;
        line-height: 1.6rem;
    }

    .AI-intern-testimonial .intern-details-college {
        font-size: 0.7rem;
        line-height: 1rem;
        font-weight: 100;
        opacity: 0.6;
    }

    .AI-intern-testimonial .intern-display-picture {
        width: 6vw;
        height: 6vw;
        border-radius: 3vw;
        border: 2px solid #fa2509;
    }

    .AI-intern-testimonial .quotes {
        width: 3vw;
        height: 3vw;
        filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
        transform: rotate(180deg);
    }

    .AI-intern-testimonial .testimonial {
        margin-top: 2vh;
        font-size: 0.9rem;
        line-height: 1.6rem;
    }

    .AI-intern-testimonial-container .resolute-button {
        margin-top: 3vh;
    }

    .AI-intern-testimonial .primary-testimonial-button {
        margin-top: 5vh;
        background-color: #fa2509;
        color: #ffffff;
        border-radius: 40px;
        font-size: 0.9rem;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1rem 1.8rem;
        gap: 0.6rem;
        border: 2px solid #fa2509;
        --c: brightness(0) invert(1);
    }

    .AI-intern-testimonial .primary-testimonial-button:hover {
        gap: 1.8rem;
    }

    .AI-intern-testimonial .primary-testimonial-button img {
        height: 1.2rem;
        width: 1.2rem;
        filter: var(--c);
    }

}

@media only screen and (max-width: 575px) {

    .testVdoCard iframe{
        height: auto;
        width: 100%;
        margin-top: 40px;
        margin: auto;
    }

    .AI-intern-testimonial {
        width: 100vw;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5vh;
    }

    .AI-intern-testimonial .carousel-blank-content {
        width: 100%;
        height: auto;
        padding: 2vw 15vw;
    }

    .AI-intern-testimonial .testimonial-card-top {
        display: flex;
        justify-content: space-between;
    }

    .AI-intern-testimonial .intern-details {
        margin-bottom: 2vh;
        display: flex;
        flex-direction: column;
        gap: 2vw;
        align-items: center;
    }

    .AI-intern-testimonial .intern-details-name {
        font-size: 1rem;
        margin-bottom: 0vw;
        line-height: 1.8rem;
        font-weight: 900;
    }

    .AI-intern-testimonial .intern-details-designation {
        font-size: 0.7rem;
        line-height: 1.4rem;
    }

    .AI-intern-testimonial .intern-details-college {
        font-size: 0.7rem;
        line-height: 1rem;
        font-weight: 100;
        opacity: 0.6;
    }

    .AI-intern-testimonial .intern-display-picture {
        width: 20vw;
        height: 20vw;
        border-radius: 10vw;
        border: 2px solid #fa2509;
        margin-bottom: 5vw;
    }

    .AI-intern-testimonial .quotes {
        display: none;
        width: 3vw;
        height: 3vw;
        filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
    }

    .AI-intern-testimonial .testimonial {
        margin-top: 2vh;
        font-size: 0.8rem;
        line-height: 1.4rem;
    }

    .AI-intern-testimonial-container .resolute-button {
        margin-top: 5vh;
    }

    .AI-intern-testimonial .primary-testimonial-button {
        margin-top: 5vh;
        background-color: #fa2509;
        color: #ffffff;
        border-radius: 40px;
        font-size: 0.9rem;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1rem 1.8rem;
        gap: 0.6rem;
        border: 2px solid #fa2509;
        --c: brightness(0) invert(1);
        margin-bottom: 7vh;
    }

    .AI-intern-testimonial .primary-testimonial-button:hover {
        gap: 1.8rem;
    }

    .AI-intern-testimonial .primary-testimonial-button img {
        height: 1.2rem;
        width: 1.2rem;
        filter: var(--c);
    }
}