@media only screen and (min-width: 576px){

.internship-details-container{
    width: 70.5vw;
    margin-left: auto;
    margin-right: auto;
    height: 12vw;
}

.job-flex-block-child{
    background-color: #1E1E1E;
    margin-top: 1vh;
    padding: 1vw;
    display: flex;
    align-items: center;
    gap: 0.6vw;
    float: left;
    margin-right: 1vw;
    margin-bottom: 0.5vw;
    font-size: 0.9rem;
}

.job-flex-block-child img{
    width: 1.2vw;
    height: 1.2vw;
    filter: brightness(0) invert(1);
}

.internship-deatils-text{
    width: 70.5vw;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.8rem;
}

.internship-perks-container{
    width: 70.5vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    height: 5vw;
}

}

@media only screen and (max-width: 575px){


.internship-details-container{
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    height: 30vh;
}

.job-flex-block-child{
    background-color: #1E1E1E;
    margin-top: 1vh;
    padding: 1vh;
    display: flex;
    align-items: center;
    gap: 1vh;
    float: left;
    margin-right: 1vw;
    margin-bottom: 0.5vw;
    font-size: 0.9rem;
}

.job-flex-block-child img{
    width: 4vw;
    height: 4vw;
    filter: brightness(0) invert(1);
}

.internship-deatils-text{
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.8rem;
}

.internship-perks-container{
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    height: 16vh;
}

}