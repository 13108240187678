@media only screen and (min-width: 576px){

.FaceGenie{
    margin-top: 10vh;
    margin-left: 14.5vw;
    margin-right: 14.5vw;
    display: flex;
    font-size: 0.9rem;
    line-height: 1.6rem;
}

.FaceGenie .left{
    width: 16vw;
    float: left;
}

.FaceGenie .left img{
    width: 12vw;
}

.FaceGenie .right{
    width: 60vw;
    float: left;
    margin-left: 2vw;
}

.FaceGenie .right section{
    font-weight: bolder;
}

.FaceGenie-Compact{
    width: 26vw;
    float: left;
    margin-top: 4vh;
}

.FaceGenie-Pro{
    width: 26vw;
    float: left;
    margin-top: 4vh;
    margin-left: 2vw;
}

.flip-card {
    background-color: transparent;
    width: 26vw;
    height: 20vw;
    margin-top: 4vh;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    width: 26vw;
    height: 20vw;
    overflow: hidden;
    object-fit: cover;
    border: 2px solid #fa2509;
}

.flip-card-front .mob{
    width: 100%;
    height: auto;
}

.flip-card-back {
    background-color: #121212;
    border: 2px solid #fa2509;
    padding: 1.2vw;
    width: 26vw;
    height: 20vw;
    transform: rotateY(180deg);
    overflow-y: scroll;
}

.flip-card-back::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
    }

.flip-card-back::-webkit-scrollbar-thumb {
    background-color: #fa2509;
}
}

@media only screen and (max-width: 575px){

.FaceGenie{
    margin-top: 8vh;
    margin-left: 5vw;
    margin-right: 5vw;
    font-size: 0.8rem;
    line-height: 1.6rem;
}

.FaceGenie .left{
    width: 90vw;
}

.FaceGenie .left img{
    width: 50vw;
    margin-bottom: 5vh;
}

.FaceGenie .right{
    width: 90vw;
}

.FaceGenie .right section{
    font-weight: bolder;
}

.FaceGenie-Compact{
    width: 42.5vw;
    margin-top: 4vh;
    margin-bottom: 5vh;
    float: left;
}

.FaceGenie-Pro{
    width: 42.5vw;
    margin-top: 4vh;
    margin-left: 5vw;
    margin-bottom: 5vh;
    float: left;
}

.flip-card {
    background-color: transparent;
    width: 42.5vw;
    height: 42.5vw;
    margin-top: 4vh;
    perspective: 1000px;
    }
    
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    width: 42.5vw;
    height: 42.5vw;
    overflow: hidden;
    object-fit: contain;
    border: 1px solid #fa2509;
}

.flip-card-front .mob{
    width: 100%;
    height: 100%;
}

.flip-card-back {
    background-color: #121212;
    border: 1px solid #fa2509;
    padding: 1.2vw;
    width: 42.5vw;
    height: 42.5vw;
    transform: rotateY(180deg);
    overflow-y: scroll;
}

.flip-card-back::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

.flip-card-back::-webkit-scrollbar-thumb {
    background-color: #fa2509;
}
}